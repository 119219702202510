import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import useLocalStorage from "../utils/useLocalStorage"

interface Props {
  data: {
    allMdx: any
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
  location: any
}

const BlogIndex = ({ data, location }: Props) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges

  const YearList = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
  `

  const YearItem = styled.div`
    color: #08760C;
    margin: 4rem 0 4rem;
  `

  const InfoPage = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `

  const [hasMounted, setHasMounted] = useState<boolean>(false);
  useEffect(() => {
    setHasMounted(true)
  }, [])

  const [showInfoPage, setShowInfoPage] = useLocalStorage('info-page', true);

  const InfoPageContent = styled.div`
    color: #08760C;
    max-width: 1000px;
    margin: 0 auto;
    font-size: 1.2rem;
  `

  if(showInfoPage && hasMounted) {
    return (
      <InfoPage onClick={() => setShowInfoPage(false)}>
        <InfoPageContent>
          💚 Velkommen til Lydknust.dk 💚
          <br/><br/>
          Festivalen Lydknust 2021 hylder 15 år med Elektronisk Musik Og Lydkunst i Esbjerg (EMOL).
          <br/><br/>
          Her på hjemmesiden kan du finde indhold produceret af teamet bag Lydknust 21’ som bla. podcasts og video-interviews. Ydermere indeholder det forskelligt materiale tidligere og nuværende studerende har indsendt til os.
          <br/><br/>
          Du kan bla. høre historien fra de komponister, der oprettede linjen. Høre studerende fortælle om deres arbejde, musik, kunst og om et liv som elektornisk komponist og lydkunstner.
          <br/><br/>
          Årgangen af EMOL-studerende fra 2019 har skabt hjemmesiden og onlinefestivalen Lydknust 2021 med ønsket om at tydeliggøre udviklingen og indflydelsen EMOL har og har haft på den danske musikscene.
          <br/><br/>
          Tak til Lars Salling for hjælp med hjemmesiden.
          <br/><br/>
          SDMK er ikke ansvarlig for denne hjemmeside og dens indhold.
          <br/><br/>
          For bedste oplevelse med hjemmesiden, brug Google Chrome
        </InfoPageContent>
      </InfoPage>
    )
  }

  return (
    <Layout location={location} title={siteTitle} logoColor="green">
      <SEO title="Årgange" />
      <YearList>
        {posts.map(({ node }: any) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <YearItem key={node.fields.slug}>
              <Link to={node.fields.slug} className="years-list__year-item-link">
                {title}
              </Link>
            </YearItem>
          )
        })}
      </YearList>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { order: ASC, fields: frontmatter___date } ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`